import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { PostType } from 'src/app/models/enums/post-type.enum';
import { Post } from 'src/app/models/post';

@Component({
  selector: 'app-profile-image',
  templateUrl: './profile-image.component.html',
  styleUrls: ['./profile-image.component.scss']
})
export class ProfileImageComponent implements OnInit {

  @Input() profileImage: string;
  @Input() fullName: string;
  @Input() isNormalCase: boolean = false;
  @Input() caption: string;
  @Input() profileStyle: object = {};
  @Input() classCaption: string;
  @Input() className: string;
  @Input() wraperWidth: boolean = false;
  @Input() post: Post | null = null;
  @Input() profileSize: string = '';
  @Input() isDisplayNoneMd: boolean = false;
  @Input() isCamdxUser: boolean = false;
  @Input() isShop: boolean = false;
  @Input() additionalText: string;
  @Input() isOnline: boolean = false;
  postType = PostType;
  @Output() onSeeTagOther = new EventEmitter();

  constructor() { }

  ngOnInit(): void { }
}
