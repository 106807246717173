<button class="profile-menu-btn" [matMenuTriggerFor]="optionMenu" [ngClass]="isMyProfile ? 'active' : ''">
  <app-profile-image *ngIf="isHome" [profileImage]="
      profile?.photo_url
        ? profile?.photo_url + '?token=' + token
        : 'assets/imgs/avatar-person.svg'
    " [fullName]="profile | fullName : ''" className="title-md font-bold text-x-sm-ellipses"
    [profileStyle]="{ width: '34px', height: '34px' }" [isDisplayNoneMd]="isDisplayNoneMd" [isCamdxUser]="profile?.camdx_user_id != null">

  </app-profile-image>
  <app-profile-image *ngIf="!isHome" [profileImage]="
      shopProfileImage
        ? shopProfileImage + '?token=' + token
        : 'assets/imgs/official-store.svg'
    " [fullName]="profile?.shops[0]?.name" className="title-lg" [profileStyle]="{ width: '34px', height: '34px' }"
    [isDisplayNoneMd]="isDisplayNoneMd" [isShop]="true">
  </app-profile-image>
  <mat-icon matSuffix>arrow_drop_down</mat-icon>
</button>
<mat-menu class="option-menu" #optionMenu="matMenu" xPosition="before">
  <div class="polygon-img"><img src="assets/imgs/polygon.svg" /></div>
  <div class="profile-menu" *ngIf="isHome">
    <app-profile-image [routerLink]="['/profile', userId]" [profileImage]="
        profile?.photo_url
          ? profile?.photo_url + '?token=' + token
          : 'assets/imgs/avatar-person.svg'
      " [fullName]="profile | fullName : ''" classCaption="caption-x-sm-ellipses"
      className="title-md font-bold text-x-sm-ellipses"
      [profileStyle]="{ width: '34px', height: '34px' }" [isCamdxUser]="profile?.camdx_user_id != null">
    </app-profile-image>
    <div *ngIf="profile?.shops.length > 0" class="swap-img" (click)="linkUserAndStoreMode()">
      <mat-icon class="icon-swap-home" svgIcon="icon-swap-home">
        <app-profile-image class="profile-images" [profileImage]="
            profile?.shops[0]?.photo_url
              ? profile?.shops[0]?.photo_url + '?token=' + token
              : 'assets/imgs/official-store.svg'
          " [profileStyle]="{ width: '24px', height: '24px' }">
        </app-profile-image>
      </mat-icon>
    </div>
  </div>
  <div class="profile-menu-shop" *ngIf="!isHome">
    <app-profile-image [routerLink]="['/store-info']" [profileImage]="
        shopProfileImage
          ? shopProfileImage + '?token=' + token
          : 'assets/imgs/official-store.svg'
      " [fullName]="profile?.shops[0]?.name" classCaption="caption-x-sm-ellipses"
      className="title-md font-bold title-profile-shop text-white"
      [profileStyle]="{ width: '34px', height: '34px' }" [isShop]="true">
    </app-profile-image>
    <div class="swap-img" (click)="linkUserAndStoreMode()">
      <mat-icon class="icon-swap" svgIcon="icon-swap-store-mode">
        <app-profile-image class="profile-images" [profileImage]="
            profile?.photo_url
              ? profile?.photo_url + '?token=' + token
              : 'assets/imgs/avatar-person.svg'
          " [profileStyle]="{ width: '24px', height: '24px' }">
        </app-profile-image>
      </mat-icon>
    </div>
  </div>
  <mat-divider *ngIf="isHome"></mat-divider>
  <div class="top-menu" *ngIf="isHome">
    <button mat-menu-item [routerLink]="['/profile/favorite']">
      <mat-icon svgIcon="icon-favorite"></mat-icon>
      {{ "Menu.Save" | translate }}
    </button>
    <button *ngIf="isLoginWithPassword" mat-menu-item class="title" [routerLink]="['/profile/change-password']"
      routerLinkActive="menu-active">
      <mat-icon svgIcon="icon-change-password"></mat-icon>
      {{ "Menu.ChangePassword" | translate }}
    </button>
    <button mat-menu-item class="title" routerLink="/profile/update-profile" routerLinkActive="menu-active">
      <mat-icon svgIcon="icon-check-list"> </mat-icon>
      {{ "Menu.EditProfile" | translate }}
    </button>
  </div>

  <div class="buttom-menu">
    <mat-divider></mat-divider>
    <button mat-menu-item (click)="changeLanguagePreference()">
      <mat-icon svgIcon="icon-web"></mat-icon>
      {{ "CurrentLang" | translate }}
    </button>
    <button class="logout-btn" mat-menu-item (click)="logout()">
      <mat-icon svgIcon="icon-logout"></mat-icon>
      {{ "Logout" | translate }}
    </button>
  </div>
</mat-menu>